import React, { useEffect, useState } from 'react';
import {
  SafeAreaView,
  StyleSheet,
  View,
  Text,
  Image,
  ScrollView,
  Alert,
  ActivityIndicator,
  Modal,
  TextInput,
  TouchableOpacity
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import Feather from 'react-native-vector-icons/Feather';

const InvitationDetailsPage = ({ route }) => {
  const [data, setData] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPw: '',
    username: '',
    platformUsername: ''
  });
  const invitationID = route.params?.InvitationID || ''; // Dynamically passed InvitationID

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://collabbay.com/APIControllers/GetLinkInvitation.php?InvitationID=${invitationID}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setData(result);
      } catch (error) {
        Alert.alert('Error', 'Failed to fetch data');
        console.error(error);
      }
    };

    if (invitationID) {
      fetchData();
    } else {
      Alert.alert('Error', 'No Invitation ID provided');
    }
  }, [invitationID]);

  const handleSignup = () => {
    const { email, password, confirmPw, username, platformUsername } = formData;
  
    const emailRegex = /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,3})$/i;
  
    // Validate fields
    if (!email || !password || !confirmPw || !username || !platformUsername) {
      Alert.alert('Error', 'All fields are required');
      return;
    }
    if (!emailRegex.test(email)) {
      Alert.alert('Error', 'Invalid email format');
      return;
    }
    if (password.length < 8 || !/[!@#$%^&*(),.?":{}|<>]/.test(password) || /\s/.test(password)) {
      Alert.alert('Error', 'Password must be at least 8 characters, include a special character, and have no spaces');
      return;
    }
    if (password !== confirmPw) {
      Alert.alert('Error', 'Passwords do not match');
      return;
    }
  
    // Ensure `ListingID` is available from `listingData`
    if (!data || !data.listingData?.ListingID) {
      Alert.alert('Error', 'Listing ID is unavailable');
      return;
    }
  
    const presetListingID = data.listingData.ListingID; // Fetch ListingID from `listingData`
  
    // API call
    fetch('https://collabbay.com/InviteSignUp.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Username: username,
        Email: email,
        Password: password,
        PlatformUsername: platformUsername.replace('@', ''),
        ListingID: presetListingID, // Use the ListingID from listingData
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.Status === 'Success') {
          alert('Welcome! Now just confirm your booking date.', response.Message || 'User registered successfully.');
          setModalVisible(false);
  
          // Redirect using window.location.href
          window.location.href = `https://collabbay.com/collaboffer/${presetListingID}`;
        } else {
          alert('Error', response.Message || 'Registration failed.');
        }
      })
      .catch((error) => {
        Alert.alert('Error', `An error occurred: ${error.message}`);
      });
  };
  
  

  if (!data) {
    return (
      <SafeAreaView style={styles.loadingContainer}>
        <ActivityIndicator size="large" color="black" />
        <Text style={styles.loadingText}>Fetching your invite...</Text>
      </SafeAreaView>
    );
  }

  const { invitationData, listingData } = data;

  return (
    <SafeAreaView style={styles.container}>
        <Image
      style={styles.smallRoundedLogo}
      source={{ uri: 'https://collabbay.com/CollabbayLogoDark.png' }}
    />
    
      <ScrollView contentContainerStyle={styles.contentContainer}>
        {/* Banner Image */}
        <Image
          source={{ uri: listingData?.LogoImage || 'https://via.placeholder.com/600x300' }}
          style={styles.bannerImage}
          resizeMode="cover"
        />

        {/* Custom Invitation Message */}
        <View style={styles.invitationMessageContainer}>
        <Text style={styles.brandName}>
  {listingData?.BrandName} is inviting you
  <Text style={{ color: '#fdf05d' }}>.</Text>
</Text>

          <Text style={styles.invitationMessage}>
            Hi {invitationData?.ProposedCreator} 👋, we are excited to invite you to our venue.
          </Text>
        </View>

        {/* Offer Details */}
        <View style={styles.cardContainer}>
          {/* Non-Credit Offer */}
          <View style={styles.card}>
            <Ionicons name="pricetag" size={24} color="#555" />
            <Text style={styles.cardTitle}>Exclusive Offer</Text>
            <Text style={styles.cardText}>{listingData?.NonCreditOffer}</Text>
          </View>

          {/* Credit Value */}
          <View style={styles.card}>
            <Ionicons name="wallet" size={24} color="#555" />
            <Text style={styles.cardTitle}>Value</Text>
            <Text style={styles.cardText}>£{listingData?.Credits}</Text>
          </View>
        </View>

        {/* Sign-Up Button */}
        <TouchableOpacity style={styles.signupButton} onPress={() => setModalVisible(true)}>
          <Text style={styles.signupButtonText}>Book Offer</Text>
        </TouchableOpacity>
      </ScrollView>

      {/* Sign-Up Modal */}
      <Modal visible={modalVisible} animationType="slide" transparent={true}>
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <Text style={styles.modalTitle}>Create your acount to access this invite.</Text>

            {/* Form Fields */}
            <TextInput
              placeholder="Username"
              style={styles.input}
              onChangeText={(text) => setFormData({ ...formData, username: text })}
            />
            <TextInput
              placeholder="Email"
              style={styles.input}
              onChangeText={(text) => setFormData({ ...formData, email: text })}
            />
            <TextInput
              placeholder="Password"
              secureTextEntry
              style={styles.input}
              onChangeText={(text) => setFormData({ ...formData, password: text })}
            />
            <TextInput
              placeholder="Confirm Password"
              secureTextEntry
              style={styles.input}
              onChangeText={(text) => setFormData({ ...formData, confirmPw: text })}
            />
            <TextInput
              placeholder="@Instagram Username"
              style={styles.input}
              onChangeText={(text) => setFormData({ ...formData, platformUsername: text })}
            />

            {/* Buttons */}
            <TouchableOpacity style={styles.modalButton} onPress={handleSignup}>
              <Text style={styles.modalButtonText}>Submit</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[styles.modalButton, { backgroundColor: '#ccc' }]}
              onPress={() => setModalVisible(false)}
            >
              <Text style={styles.modalButtonText}>Cancel</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'black',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentContainer: {
    maxWidth: 600,
    width: '100%',
    alignSelf: 'center',
    paddingHorizontal: 16,
    paddingBottom: 20,
  },
  bannerImage: {
    width: '100%',
    height: 150,
    borderRadius: 15,
    marginBottom: 20,
    marginTop: 40,
  },
  invitationMessageContainer: {
    backgroundColor: 'black',
    padding: 16,
    borderRadius: 10,
    marginBottom: 20,
    alignItems: 'center',
  },
  brandName: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#fff',
    marginBottom: 10,
  },
  invitationMessage: {
    fontSize: 18,
    color: '#fff',
    textAlign: 'center',
  },
  cardContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  card: {
    width: '48%',
    backgroundColor: '#fff',
    padding: 16,
    borderRadius: 10,
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowRadius: 10,
    alignItems: 'center',
  },
  cardTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 8,
    color: '#333',
  },
  cardText: {
    fontSize: 14,
    color: '#555',
    textAlign: 'center',
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f7f7f7',
  },
  loadingText: {
    marginTop: 10,
    fontSize: 16,
    color: '#555',
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  modalContent: {
    width: '90%',
    backgroundColor: '#fff',
    borderRadius: 10,
    padding: 20,
    alignItems: 'center'
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 20
  },
  input: {
    width: '100%',
    height: 50,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    marginBottom: 20,
    fontSize: 16,
    paddingHorizontal: 10
  },
  modalButton: {
    backgroundColor: 'black',
    padding: 15,
    borderRadius: 5,
    width: '100%',
    alignItems: 'center',
    marginBottom: 10
  },
  modalButtonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold'
  },
  signupButton: {
    backgroundColor: '#FDF05D',
    padding: 15,
    borderRadius: 5,
    alignItems: 'center',
    marginTop: 20
  },
  signupButtonText: {
    color: '#000',
    fontSize: 16,
    fontWeight: 'bold'
  },
  smallRoundedLogo: {
    width: 40, // Small logo size
    height: 40,
    borderRadius: 20, // Fully rounded
    backgroundColor: 'black', // Optional: background color if logo has transparency
    marginRight: 10, // Space between logo and text
    marginTop: 10, // Margin on top
    alignSelf: 'center', // Centers the logo vertically within its parent container
  },
});

export default InvitationDetailsPage;
