import React, { useEffect, useState } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import Homepage from './Homepage';
import CreateSubmissionPage from './CreateSubmissionPage';
import UserRecapPage from './UserRecapPage';
import UserCollabDashboard from './UserCollabDashboard';
import CollabCampaignDetailsPage from './CollabCampaignDetailsPage';
import UserGetAssignQRPage from './UserGetAssignQRPage';
import UserGetQRSearch from './UserGetQRSearch';
import QRCodeScannerPage from './QRCodeScannerPage';
import BrandURLLandingPage from './BrandURLLandingPage';
import MediaUpload from './MediaUpload';
import SignUpPage from './SignUpPage';
import LogInPage from './LogInPage';
import CollabCalendar from './CollabCalendar';
import BrandListings from './BrandListings';
import InfluencerInviteLink from './InfluencerInviteLink';
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';

const Stack = createStackNavigator();

const App = () => {
  const [username, setUsername] = useState(null);

  useEffect(() => {
    const fetchSessionData = async () => {
      try {
        const response = await fetch('https://collabbay.com/APIControllers/GetSessionData.php');
        const data = await response.json();

        if (data.Username) {
          setUsername(data.Username);
        } else {
          setUsername(null);
        }
      } catch (error) {
        console.error('Error fetching session data:', error);
      }
    };

    fetchSessionData();
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/collab/:CollabID" element={<BrandURLLandingPage />} />
        <Route path="/collaboffer/:ListingID" element={<CreateSubmissionPageWrapper />} />
        <Route path="/invite/:InvitationID" element={<InfluencerInviteLinkWrapper />} />
        <Route path="/" element={<AppContent username={username} />} />
      </Routes>
    </Router>
  );
};

const AppContent = ({ username }) => {
  return (
    <NavigationContainer>
      <Stack.Navigator initialRouteName="Homepage" screenOptions={{ headerShown: false }}>
        <Stack.Screen name="Homepage" component={Homepage} />
        {username && (
          <>
            <Stack.Screen name="CreateSubmissionPage" component={CreateSubmissionPage} />
            <Stack.Screen name="UserRecapPage" component={UserRecapPage} />
            <Stack.Screen name="UserCollabDashboard" component={UserCollabDashboard} />
            <Stack.Screen name="CollabCampaignDetailsPage" component={CollabCampaignDetailsPage} />
            <Stack.Screen name="UserGetAssignQRPage" component={UserGetAssignQRPage} />
            <Stack.Screen name="UserGetQRSearch" component={UserGetQRSearch} />
            <Stack.Screen name="QRCodeScannerPage" component={QRCodeScannerPage} />
            <Stack.Screen name="MediaUpload" component={MediaUpload} />
            <Stack.Screen name="CollabCalendar" component={CollabCalendar} />
            <Stack.Screen name="BrandListings" component={BrandListings} />
            <Stack.Screen name="InfluencerInviteLink" component={InfluencerInviteLink} />
          </>
        )}
        <Stack.Screen name="LogInPage" component={LogInPage} />
        <Stack.Screen name="SignUpPage" component={SignUpPage} />
        <Stack.Screen name="BrandURLLandingPage" component={BrandURLLandingPage} />
      </Stack.Navigator>
    </NavigationContainer>
  );
};

const CreateSubmissionPageWrapper = () => {
  const { ListingID } = useParams();

  // Mimic React Navigation's route and navigation objects for compatibility
  const route = { params: { ListingID } };
  const navigation = {
    goBack: () => window.history.back(),
  };

  return <CreateSubmissionPage route={route} navigation={navigation} />;
};

const InfluencerInviteLinkWrapper = () => {
  const { InvitationID } = useParams();

  // Mimic React Navigation's route and navigation objects for compatibility
  const route = { params: { InvitationID } };
  const navigation = {
    goBack: () => window.history.back(),
  };

  return <InfluencerInviteLink route={route} navigation={navigation} />;
};

export default App;
