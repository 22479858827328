import React, { useState, useEffect } from 'react';
import {
  SafeAreaView,
  StyleSheet,
  View,
  Text,
  ScrollView,
  Image,
  TouchableOpacity,
  TextInput,
  Alert,
  Modal,
  Video,
  Pressable,
  Linking,
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';

const App = ({ navigation }) => {
  const [searchText, setSearchText] = useState('');
  const [userData, setUserData] = useState({});
  const [recentCollabs, setRecentCollabs] = useState([]);
  const [showTutorial, setShowTutorial] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedCollab, setSelectedCollab] = useState(null);

  const handleCollabPress = (collab) => {
    if (collab.Status === 'RED') {
      setSelectedCollab(collab); // Set the collab for the modal
      setModalVisible(true); // Show the blocking modal
    } else {
      // Open the link directly for YELLOW or other statuses
      Linking.openURL(`https://collabbay.com/UploadPost.html?CollabID=${collab.CollabID}`);
    }
  };
  
  
  
  useEffect(() => {
    fetchUserData();
    fetchRecentCollabs();
  }, []);

  useEffect(() => {
    console.log("Current step: ", currentStep);
    console.log("Is tutorial showing: ", showTutorial);
  }, [currentStep, showTutorial]);

  const tutorialSteps = [
    {
      message: `Welcome to Collabbay, the platform that connects influencers like you with top brands and unique experiences—from dining to spa days and more. Get access to exclusive offers in exchange for creating engaging content during your visits.`,
      icon: null,
    },
    {
      message: `Browse brand collab offers, find something you like? Simply book a visit and await approval, we'll need to verify you before you can create bookings.`,
      icon: 'search',
    },
    {
      message: `Manage and view upcoming collabs via your calendar, your central manager for tracking upcoming brand collabs at different venues.`,
      icon: 'calendar',
    },
    {
      message: `Each collab has a QR-code card linked to the offer. Instead of paying at venues, present your QR for the brand to scan and redeem your offer. View and manage your QR vouchers via this dashboard.`,
      icon: 'qr-code',
    },
    {
      message: `Your profile is seen by brands, as well as us. Add a profile picture and a bio with your interests.`,
      icon: 'person',
    },
    {
      message: `Welcome to Collabbay, where collabs are your new currency. Update your profile and await a verification message. New venues and offers will be listed on collabbay recurringly.`,
      icon: null,
    },
  ];

  const fetchUserData = async () => {
    try {
      const response = await fetch('https://collabbay.com/APIControllers/GetSessionData.php');
      const data = await response.json();
      setUserData(data);
  
      // If the user hasn't seen the tutorial, show it
      if (data.HasSeenTutorial ==="0") {
        setShowTutorial(true);
      }
    } catch (error) {
      console.error('Error fetching user data: ', error);
    }
  };

  const [filteredCollabs, setFilteredCollabs] = useState([]);


  useEffect(() => {
    const fetchFilteredCollabs = async () => {
      try {
        const response = await fetch('https://collabbay.com/APIControllers/InfluencerGetContentRAG.php');
        const data = await response.json();
  
        if (data.success) {
          const filteredData = data.results.filter(collab => collab.Status === 'RED' || collab.Status === 'YELLOW');
          setFilteredCollabs(filteredData);
  
          // Automatically show the modal if there is a RED status
          const redCollab = filteredData.find(collab => collab.Status === 'RED');
          if (redCollab) {
            setSelectedCollab(redCollab); // Set the collab for the modal
            setModalVisible(true); // Show the modal
          }
        }
      } catch (error) {
        console.error('Error fetching collabs:', error);
      }
    };
  
    fetchFilteredCollabs();
  }, []);

  
  const fetchRecentCollabs = () => {
    fetch('https://collabbay.com/APIControllers/RetrieveRecentCollabs.php')
      .then(response => response.json())
      .then(data => {
        setRecentCollabs(data);
      })
      .catch(error => {
        console.error('Error fetching recent collabs: ', error);
      });
  };

  const handleSearch = () => {
    if (searchText.trim() !== '') {
      navigation.navigate('CreateSubmissionPage', { ListingID: searchText });
    }
  };

  const handlePersonIconPress = () => {
    if (userData.Username) {
      navigation.navigate('UserRecapPage');
    } else {
      navigation.navigate('LogInPage');
    }
  };

  const handleOfferPress = () => {
    navigation.navigate('BrandListings');
  };

  const handleLogout = async () => {
    try {
      const response = await fetch('https://collabbay.com/APIControllers/EndSession.php');
      const data = await response.json();
      if (data.success) {
        Alert.alert('Logout', 'Logout successful');
        navigation.navigate('Homepage');
      } else {
        Alert.alert('Logout', 'No active session found');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleScanIconPress = () => {
    Linking.openURL('https://collabbay.com/QR.html');
  };

  const handleNextStep = () => {
    if (currentStep < tutorialSteps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      updateHasSeenTutorial();  // Update on the server
      setShowTutorial(false);  // Hide the tutorial
    }
  };

  const updateHasSeenTutorial = () => {
    fetch('https://collabbay.com/APIControllers/UpdateHasSeenTutorial.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        UserID: userData.UserID,
        HasSeenTutorial: 1,
      }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.Status !== 'Success') {
          console.error('Error updating tutorial status: ', data.Message);
        }
      })
      .catch(error => {
        console.error('Error updating tutorial status: ', error);
      });
  };

  const handleRedoTutorial = () => {
    fetch('https://collabbay.com/APIControllers/RedoTutorial.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => {
        if (data.Status === 'Success') {
          console.log('Tutorial reset successfully.');
          setCurrentStep(0);  // Reset to the first step
          setShowTutorial(true);  // Make sure tutorial shows again
        } else {
          console.error('Error resetting tutorial status: ', data.Message);
        }
      })
      .catch(error => {
        console.error('Error resetting tutorial status: ', error);
      });
  };

  

  const renderTutorialButton = () => (
    <TouchableOpacity style={styles.redoButton} onPress={handleRedoTutorial}>
       <Ionicons name="help-circle" size={20} color="black" />
    </TouchableOpacity>
  );

  const renderTutorial = () => {
    if (!showTutorial || currentStep >= tutorialSteps.length) {
      return null;
    }

    const step = tutorialSteps[currentStep];

    return (
      <Modal transparent visible={showTutorial} animationType="slide">
        <View style={styles.modalOverlay}>
          <View style={styles.tutorialCard}>
            {step.icon && (
              <Ionicons name={step.icon} size={60} color="black" style={styles.iconLarge} />
            )}
            <Text style={styles.tutorialText}>{step.message}</Text>
            <Pressable style={styles.nextButton} onPress={handleNextStep}>
              <Text style={styles.buttonText}>
                {currentStep < tutorialSteps.length - 1 ? 'Next' : 'Finish'}
              </Text>
            </Pressable>
          </View>
        </View>
      </Modal>
    );
  };

  return (
    <SafeAreaView style={[styles.container, { width: '100%', maxWidth: 600, alignSelf: 'center', backgroundColor: '#fff', flex: 1 }]}>
  

{modalVisible && selectedCollab && (
  <View
    style={{
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 10, // Ensures the modal is on top of everything
    }}
  >
    <View
      style={{
        backgroundColor: '#fff',
        borderRadius: 10,
        padding: 20,
        width: '80%',
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.2,
        shadowRadius: 5,
        elevation: 5,
      }}
    >
      <Text
        style={{
          fontSize: 18,
          fontWeight: 'bold',
          marginBottom: 15,
          textAlign: 'center',
          color: '#000',
        }}
      >
        Urgent Action Required
      </Text>
      <Text
        style={{
          fontSize: 16,
          textAlign: 'center',
          color: '#555',
          marginBottom: 20,
        }}
      >
        Content for <b>{selectedCollab.BrandName}</b> is overdue. Please take immediate action. The venue is aware. 
        Terms of Use and Local Law apply. <br>
        </br><b>Your account is locked until you upload the content. </b>
      </Text>
      <TouchableOpacity
        onPress={() =>
          Linking.openURL(`https://collabbay.com/UploadPost.html?CollabID=${selectedCollab.CollabID}`)
        }
        style={{
          backgroundColor: '#eb5757',
          borderRadius: 5,
          paddingVertical: 10,
          paddingHorizontal: 20,
          marginBottom: 10,
        }}
      >
        <Text style={{ color: '#fff', fontWeight: 'bold', fontSize: 16 }}>Upload pending content</Text>
      </TouchableOpacity>
    </View>
  </View>
)}

      {renderTutorialButton()}
      <ScrollView contentContainerStyle={styles.contentContainer}>
      <View style={styles.header}>
  <View style={styles.headerRow}>
    {/* Logo */}
    <Image
      style={styles.smallRoundedLogo}
      source={{ uri: 'https://collabbay.com/CollabbayLogoDark.png' }}
    />
    
    {/* Conditionally render 'Hi' text or prompt to log in */}
    {userData.Username ? (
      <Text style={styles.headerText}>Hi 👋, {userData.Username}</Text>
    ) : (
<TouchableOpacity 
  onPress={() => navigation.navigate('LogInPage')}
  style={{ marginBottom: 15 }}
>

        <Text style={styles.headerText}>👋 Time to create.</Text>
      </TouchableOpacity>
    )}
  </View>
</View>


        <View style={styles.section}>
          <View style={styles.sectionHeader}>

            {!userData.Username && (
              <TouchableOpacity
                style={styles.signUpButton}
                onPress={() => navigation.navigate('SignUpPage')}
              >
                <Text style={styles.signUpButtonText}>Sign up as a content creator</Text>
              </TouchableOpacity>
            )}
           
           {!userData.Username && (
           <TouchableOpacity
                style={styles.signUpButtonTwo}
                onPress={() => navigation.navigate('LogInPage')}
              >
                <Text style={styles.signUpButtonTextTwo}> <Ionicons name="person" size={12} color="white" /> Log In</Text>
              </TouchableOpacity>
       
        )}
          </View> 

          <ScrollView horizontal showsHorizontalScrollIndicator={false}>
  {/* First card with video as background */}
  <View style={styles.card}>
    <video
      autoPlay
      loop
      muted
      playsInline
      style={styles.videoBackground}
    >
      <source src="https://collabbay.com/App%20Video%20-%20Influencer%20Recap.mp4" type="video/mp4" />
    </video>
  </View>

  {/* Other cards with text */}
  <View style={styles.card}>
    <Text style={styles.cardTitle}> Browse offers
    <Text style={{ fontWeight: 'bold', color: '#FDF05D' }}>.</Text></Text>
    <TouchableOpacity onPress={() => navigation.navigate('CreateSubmissionPage')}>
  <Text style={styles.cardSubTitle}>

</Text>
    </TouchableOpacity>
  </View>
  
  <View style={styles.card}>
    <video
      autoPlay
      loop
      muted
      playsInline
      style={styles.videoBackground}
    >
      <source src="https://collabbay.com/Influencer%20App%20Video%20-%20II.mp4" type="video/mp4" />
    </video>
  </View>


  <View style={styles.card}>
    <Text style={styles.cardTitle}>Create<Text style={{ fontWeight: 'bold', color: '#FDF05D' }}>.</Text></Text>
    <Text style={styles.cardSubTitle}>Go #viral<Text style={{ fontWeight: 'bold', color: '#FDF05D' }}>.</Text></Text>
  </View>
</ScrollView>
       
        </View>

        <View style={styles.offerContainer}>
          <TouchableOpacity style={styles.offerButton} onPress={handleOfferPress}>
            <Ionicons name="search" size={24} color="black" style={styles.offerIcon} />
            <Text style={styles.offerText}> Browse Brand Offers</Text>
          </TouchableOpacity>

          

          {filteredCollabs.length > 0 &&
  filteredCollabs.map((collab, index) => {
    const dueDate = new Date(collab.LatestDeliveryDate);
    const today = new Date();
    const daysLeft = Math.ceil((dueDate - today) / (1000 * 60 * 60 * 24));

    return (
      <TouchableOpacity
        key={index}
        onPress={() => handleCollabPress(collab)}
        style={{
          borderRadius: 10,
          marginTop: 15,
          borderWidth: 2,
          width: '90%',
          borderColor: collab.Status === 'YELLOW' ? '#f2c94c' : '#eb5757',
          backgroundColor: '#f9f9f9',
          padding: 15,
          alignSelf: 'center',
        }}
      >
        <View style={{ alignItems: 'flex-start' }}>
          <Ionicons name="time-outline" size={24} color="black" style={{ marginBottom: 5 }} />
          <Text style={{ fontSize: 20, fontWeight: 'bold', color: '#000', marginBottom: 5 }}>
            {collab.Status === 'YELLOW'
              ? `Pending content due in ${daysLeft} days`
              : 'Urgent action required. Content overdue.'}
          </Text>
          <Text style={{ fontSize: 16, color: '#555' }}>for {collab.BrandName}</Text>
        </View>
      </TouchableOpacity>
    );
  })}



<TouchableOpacity
  onPress={() => navigation.navigate('LogInPage')}
  style={{
    flexDirection: 'row',
    alignItems: 'center', // Align items to the center // Light gray background
    borderRadius: 10, // Rounded corners
    padding: 10, // Padding around the content
    marginTop: 2, // Top margin
    marginBottom: 5, // Bottom margin
  }}
>
  {!userData.Username && (
    <>
      <Ionicons name="log-in-outline" size={20} color="black" style={{ marginRight: 10 }} />
      <Text style={{ color: 'black', fontSize: 12, fontWeight: 'bold' }}>
        Log in to view...
      </Text>
    </>
  )}
</TouchableOpacity>


        </View>

        {/* <View style={styles.scanOptionalContainer}>
          <Text>or scan the QR in-venue</Text>
        </View> */}

        <View style={styles.bottomBackground}>
          {/* <TouchableOpacity onPress={handleScanIconPress}>
            <Ionicons name="scan" size={200} color="black" style={styles.qrIcon} />
          </TouchableOpacity> */}

          <View style={styles.searchBar}>
            <Ionicons name="search" size={24} color="white" style={styles.searchIcon} />
            <TextInput
              style={styles.searchInput}
              placeholder="or find a collabbay venue by ID"
              placeholderTextColor="white"
              onChangeText={(text) => setSearchText(text)}
              onSubmitEditing={handleSearch}
            />
          </View>

          {userData.Username && recentCollabs.length > 0 && (
            <View style={styles.section}>
              <Text style={styles.sectionTitle}>My Recent collabs</Text>
              {recentCollabs.map((collab, index) => (
                <TouchableOpacity
                  key={index}
                  style={styles.collabCard}
                  onPress={() => navigation.navigate('CollabCampaignDetailsPage', { CollabID: collab.CollabID })}
                >
                  <Text style={styles.collabCardTitle}>{collab.BrandName}</Text>
                  <Text style={styles.collabCardSubTitle}>{collab.DateTime}</Text>
                  <Text style={styles.collabCardSubTitle}>{collab.AdHeader}</Text>
                </TouchableOpacity>
              ))}
            </View>
          )}
        </View>
      </ScrollView>

      <TouchableOpacity 
  onPress={() => Linking.openURL('https://collabbay.com/PrivacyPolicy.html')}
  style={{
    marginTop: 7, 
    marginBottom: 7
  }}
>
  <Text style={{ fontSize: 12, color: 'black', textDecorationLine: 'underline', textAlign: 'center' }}>
    By using collabbay, you agree to our Privacy Policy.
  </Text>
</TouchableOpacity>

      <View style={styles.footer}>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('Homepage')}>
          <Ionicons name="home" size={24} color="black" />
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('UserCollabDashboard')}>
          <Ionicons name="qr-code" size={24} color="black" />
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('CollabCalendar')}>
          <Ionicons name="calendar" size={24} color="black" />
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon} onPress={handleScanIconPress}>
          <Ionicons name="scan" size={24} color="black" />
        </TouchableOpacity>
        {userData.Username ? (
          <TouchableOpacity style={styles.footerIcon} onPress={handlePersonIconPress}>
            <Ionicons name="person" size={24} color="black" />
          </TouchableOpacity>
        ) : (
          <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('LogInPage')}>
            <Ionicons name="person" size={24} color="black" />
          </TouchableOpacity>
        )}
      </View>

      {renderTutorial()}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  contentContainer: {
    paddingHorizontal: 10,
  },
  bannerContainer: {
    marginBottom: 20,
    borderRadius: 10,
    marginTop: 20,
    backgroundColor: 'black',
    width: '100%',
    height: 80,
  },
  bannerImage: {
    width: '100%',
    height: '100%',
    borderRadius: 10,
  },
  searchBar: {
    backgroundColor: 'black',
    borderRadius: 20,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 15,
    paddingVertical: 10,
    marginTop: 40,
    marginBottom: 10,
  },
  searchInput: {
    flex: 1,
    color: 'white',
    fontSize: 16,
    marginLeft: 10,
  },
  section: {
    marginBottom: 20,
  },
  sectionTitle: {
    fontSize: 15,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  card: {
    width: 157,
    height: 187,
    backgroundColor: 'black', // Fallback color in case the video fails
    marginRight: 10,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative', // Ensures that the video fills the entire card
  },
  videoBackground: {
    position: 'absolute', // Position the video inside the card only
    top: 0,
    left: 0,
    width: '100%', // Ensure the video covers the entire width of the card
    height: '100%', // Ensure the video covers the entire height of the card
    borderRadius: 10, // Keep the border radius of the card
    zIndex: 0, // Ensure the video stays behind the text
  },
  cardTitle: {
    fontSize: 19,
    fontWeight: 'bold',
    color: 'white',
    fontSize: 11,
    textAlign: 'center',
    zIndex: 1, // Ensure text is above video (not needed here since there's no text)
  fontSize: 15,
  },
  cardSubTitle: {
    color: 'white',
    marginTop: 5,
    textAlign: 'center',
    zIndex: 1, // Ensure subtitle is above video (not needed here since there's no text)
  },
  collabCard: {
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 10,
    marginBottom: 10,
  },
  collabCardTitle: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  collabCardSubTitle: {
    fontSize: 14,
    color: 'gray',
  },
  qrIcon: {
    alignSelf: 'center',
    marginTop: 20,
  },
  redoButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    backgroundColor: '#FDF05D',
    padding: 6,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    position: 'sticky',
    bottom: 0,
    backgroundColor: 'white',
    left: 0,
    right: 0,
    paddingVertical: 10,
  },
  footerIcon: {
    alignItems: 'center',
  },
  bottomBackground: {
    backgroundColor: '#FDF05D',
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    paddingHorizontal: 10,
    paddingBottom: 120,
  },
  sectionHeader: {
    flexDirection: 'row',
    
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  signUpButton: {
    backgroundColor: '#FDF05D',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 20,
    borderColor: 'black',
  },
  signUpButtonTwo: {
    backgroundColor: 'black',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: 'black',
  },
  signUpButtonText: {
    color: 'black',
    fontSize: 14,
    fontWeight: 'bold',
  },
  signUpButtonTextTwo: {
    color: 'white',
    fontSize: 14,
    fontWeight: 'bold',
  },
  offerButton: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#FDF05D',
    paddingHorizontal: 15,
    paddingVertical: 10,
    borderRadius: 15,
    marginTop: 10,
    marginBottom: 10,
    width: '77%',
    justifyContent: 'center',
  },
  offerIcon: {
    marginRight: 10,
  },
  offerText: {
    color: 'black',
    fontSize: 16,
    fontWeight: 'bold',
  },
  offerLogText: {
    color: 'black',
    fontSize: 12,
    fontWeight: 'bold',
  },
  offerContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  scanOptionalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  modalOverlay: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tutorialCard: {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 10,
    alignItems: 'center',
    width: '80%',
  },
  iconLarge: {
    marginBottom: 15,
  },
  tutorialText: {
    fontSize: 16,
    textAlign: 'center',
    marginBottom: 10,
  },
  nextButton: {
    marginTop: 10,
    padding: 10,
    backgroundColor: '#000',
    borderRadius: 5,
  },
  buttonText: {
    color: 'white',
    fontWeight: 'bold',
  },
  linkText: {
    fontSize: 9,
    color: 'black',
    textDecorationLine: 'underline',
    textAlign: 'center',
  },

  header: {
    width: '100%',
    paddingTop: 20,
    paddingBottom: 10,
    paddingHorizontal: 2,
    backgroundColor: '#fff', // Optional: background color for header
  },

  headerRow: {
    flexDirection: 'row', // Align logo and text horizontally
    alignItems: 'center', // Center items vertically
    justifyContent: 'flex-start', // Align items to the left
    width: '100%',
  },

  smallRoundedLogo: {
    width: 40, // Small logo size
    height: 40,
    borderRadius: 20, // Fully rounded
    backgroundColor: 'black', // Optional: background color if logo has transparency
    marginRight: 10, // Space between logo and text
  },

  headerText: {
    fontSize: 23, // Adjust text size
    fontWeight: 'bold',
    color: 'black', // Text color
    textAlign: 'left', // Align text to the left
    flex: 1, // Allow text to take remaining space
  },
});

export default App;
