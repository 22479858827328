import React, { useState, useEffect } from 'react';
import { SafeAreaView, StyleSheet, View, Text, ScrollView, TextInput, TouchableOpacity, Alert, Image } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { Calendar } from 'react-native-calendars';
import { Linking } from 'react-native';

const CreateSubmissionPage = ({ route, navigation }) => {
  const [listingData, setListingData] = useState(null);
  const [logoImage, setLogoImage] = useState('');

  const [collabData, setCollabData] = useState({
    ListingID: '',
    BrandName: '',
    BrandURL: '',
    BrandUserID: '',
    Redeem_Message: '',
    AdHeader: '',
    ListingDescription: '',
    Credits: '',
    CreditAvailableStart: '',
    Message: '',
    PreferredAvailability: '',
    ContentBrief: '',
  });
  
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('1:00 AM');
  const [message, setMessage] = useState('');
  const [bookedSlots, setBookedSlots] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [addedVisitors, setAddedVisitors] = useState(0);

  const [showWarning, setShowWarning] = useState(false);
  const [minFollowersRequired, setMinFollowersRequired] = useState(null);


  const areAllFieldsFilled = () => {
    return (
      Object.keys(collabData)
        .filter(key => key !== 'Message') // Exclude "Message" field
        .every(key => collabData[key] !== '' && collabData[key] !== null) &&
      selectedDate &&
      selectedTime
    );
  };



  const parsePreferredAvailability = (preferredAvailability) => {
    if (!preferredAvailability) return [];
    return preferredAvailability.split(',').map((block) => block.trim());
  };



  const renderPreferredAvailability = () => {
    const parsedAvailability = parsePreferredAvailability(collabData.PreferredAvailability);
  
    if (parsedAvailability.length === 0) {
      return <Text style={styles.noAvailabilityText}>No availability data provided</Text>;
    }
  
    return (
      <View style={styles.availabilityContainer}>
        <Text style={styles.availabilityHeader}>Booking Availability</Text>
        <View style={styles.availabilityList}>
          {parsedAvailability.map((entry, index) => (
            <View key={index} style={styles.availabilityItem}>
              <Text style={styles.availabilityText}>{entry}</Text>
            </View>
          ))}
        </View>
      </View>
    );
  };
  
  
  
  


  const handleBackPress = () => {
    navigation.goBack();
  };

  useEffect(() => {
    const checkPromoterVerificationStatus = async () => {
      try {
        const response = await fetch('https://collabbay.com/APIControllers/CheckPromoterVerificationStatus.php', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include' // Ensure cookies are sent to maintain session
        });
        const data = await response.json();
  
        // Check if data.Status is null or any value other than 'Public Figure'
        if (!data.Status || data.Status !== 'Public Figure') {
          setShowWarning(true);
        }
      } catch (error) {
        console.error('Error fetching promoter verification status:', error);
      }
    };
  
    checkPromoterVerificationStatus();
  }, []);

 
  useEffect(() => {
    const fetchListingData = async () => {
      try {
        const response = await fetch(`https://collabbay.com/APIControllers/RetrieveOfferDescription.php?ListingID=${route.params.ListingID}`);
        if (!response.ok) {
          throw new Error('Failed to fetch listing data');
        }
        const data = await response.json();
        setListingData(data);  // Store the full response in the state
  
        // Populate the form fields with the fetched data
        setMinFollowersRequired(data?.MinFollowersRequired);
        setCollabData(prevCollabData => ({
          ...prevCollabData,
          ListingID: route.params.ListingID,
          BrandName: data.BrandName || '',
          BrandURL: data.BrandURL || '',
          BrandUserID: data.BrandUserID || '',
          Redeem_Message: data.Redeem_Message || '',
          AdHeader: data.AdHeader || '',
          ListingDescription: data.ListingDescription || '',
          Credits: data.Credits || '',
          PreferredAvailability: data.PreferredAvailability || '',
          ContentBrief: data.ContentBrief || '',
          LogoImage: data.LogoImage || '',
          Location: data.Location || '',
          MinFollowersRequired: data.MinFollowersRequired || '',  // Set MinFollowersRequired field
          NonCreditOffer: data.NonCreditOffer || '',  // Set NonCreditOffer field
          TurnAroundDays: data.TurnAroundDays || '',
        }));
      } catch (error) {
        console.error('Error fetching listing data:', error);
      }
    };
  
    fetchListingData();
  }, [route.params.ListingID]);
  
  
  

  // Fix: fetchBookedSlots function to properly handle the PHP API response and parse date
  useEffect(() => {
    const fetchBookedSlots = async () => {
      try {
        const response = await fetch(`https://collabbay.com/APIControllers/RetrieveBookedTimes.php?ListingID=${route.params.ListingID}`);
        if (!response.ok) {
          throw new Error('Failed to fetch booked slots');
        }
        const data = await response.text(); // Get the plain text response
        const slots = data
          .split('<br>') // Split the data by line breaks
          .map(slot => slot.replace('CreditAvailableStart: ', '').trim()) // Remove the label
          .filter(Boolean); // Remove any empty entries
        setBookedSlots(slots); // Updated to handle the response correctly
      } catch (error) {
        console.error('Error fetching booked slots:', error);
      }
    };

    fetchBookedSlots();
  }, [route.params.ListingID]);

 


  const handleCollabPress = async () => {
  try {
    const fullDateTime = `${selectedDate} ${selectedTime}`;
    
    const updatedCollabData = {
      ...collabData,
      CreditAvailableStart: fullDateTime,
      Message: message.trim(),
    };
    
    const postData = {
      ...updatedCollabData,
      AddedVisitors: addedVisitors,
    };
    
    const response = await fetch('https://collabbay.com/APIControllers/InsertCollabSubmission.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    });
    
    const result = await response.json();
    
    if (response.ok && result.success) {
      alert('Booking request sent. If the venue approves the collab, you will receive a notification. Otherwise, the request will expire.');
      window.location.href = result.redirectUrl; // Redirect on success
    } else if (result.error === "Selected date and time do not match the brand's preferred availability.") {
      // Show an alert if booking is outside preferred availability
      alert('Booking Error. Check that your selected date and time is within the venues opening time.', 'The selected date and time are not within the brand’s preferred availability.');
    } else if (result.message) {
      alert('This offer has a minimum follower requirement.');
    } else {
      alert('Error', result.error || 'An error occurred during submission.');
    }
  } catch (error) {
    Alert.alert('Error', 'An error occurred while submitting the form.');
    console.error('Submission Error:', error);
  }
};

  
  

  useEffect(() => {
    const fetchLogoImage = async () => {
      try {
        // Use listingData?.BrandName dynamically in the fetch URL
        const response = await fetch(`https://collabbay.com/APIControllers/GetBrandLogoImage.php?BrandName=${listingData?.BrandName}`);
        
        if (!response.ok) {
          throw new Error('Failed to fetch logo image');
        }
  
        const data = await response.json();
  
        if (data.success) {
          // Set the ProfileImage from the response
          setLogoImage(data.ProfileImage);
        } else {
          console.error('Logo image fetch unsuccessful');
        }
      } catch (error) {
        console.error('Error fetching logo image:', error);
      }
    };
  
    // Ensure listingData?.BrandName exists before calling the fetch function
    if (listingData?.BrandName) {
      fetchLogoImage();
    }
  
  }, [listingData?.BrandName]);

  // Render Booked Slots: Update this to echo the correct format
  const renderBookedSlots = () => {
    const displayedSlots = expanded ? bookedSlots : bookedSlots.slice(0, 3);
    
    return (
      <View style={styles.bookedSlotsContainer}>
        <Text style={styles.bookedSlotsTitle}>Reserved Slots</Text>
        <Text style={styles.bookedSlotsBody}>You currently won't be able to book these over the next week</Text>
        {displayedSlots.map((slot, index) => {
          // Assuming slot is in format 'Mon Sep 31 2024 17:30'
          const dateTime = new Date(slot); // Create Date object
          const dateString = dateTime.toDateString(); // e.g., 'Mon Sep 31 2024'
          const timeString = dateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }); // e.g., '17:30'
          return (
            <View key={index} style={styles.bookedSlot}>
              <Text style={styles.bookedSlotText}>{dateString} - {timeString}</Text>
            </View>
          );
        })}
        {bookedSlots.length > 3 && (
          <TouchableOpacity
            style={styles.expandButton}
            onPress={() => setExpanded(!expanded)}
          >
            <Ionicons name={expanded ? "chevron-up" : "chevron-down"} size={16} color="black" />
            <Text style={styles.expandButtonText}>
              {expanded ? 'Show Less' : 'Show All'}
            </Text>
          </TouchableOpacity>
        )}
      </View>
    );
  };

  const renderContentBrief = () => {
    if (collabData.ContentBrief) {
      const contentBriefItems = collabData.ContentBrief.split(',').map(item => item.trim());
      return (
        <View style={styles.contentBriefContainer}>
          <Text style={styles.sectionTitle}>Content Brief</Text>
          {contentBriefItems.map((item, index) => (
            <Text key={index} style={styles.bulletPoint}>• {item}</Text>
          ))}
        </View>
      );
    }
    return null;
  };

  return (
    <SafeAreaView style={styles.container}>
     <ScrollView
  style={{
    maxWidth: 600,
    width: '100%',
    alignSelf: 'center',
    flex: 1, // Optional, if needed to fill the parent view
  }}
  contentContainerStyle={styles.contentContainer}
>
  
        <View style={styles.bannerContainer}>
          {/* Top Image: listingData.LogoImage */}
          <Image
            source={{ uri: listingData?.LogoImage || 'https://images.pexels.com/photos/1058277/pexels-photo-1058277.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' }}
            style={styles.bannerImage}
            resizeMode="cover"
          />

          <View style={styles.backContainer}>
            <TouchableOpacity style={styles.backButton} onPress={handleBackPress}>
              <Ionicons name="arrow-back" size={28} color="#FDF05D" />
            </TouchableOpacity>
          </View>

          {/* Bottom Image: logoImage */}
          <View style={styles.logoContainer}>
  {logoImage ? (
    <Image
      source={{ uri: logoImage }}  // Use the logoImage state directly
      style={styles.logo}
      resizeMode="cover"
    />
  ) : null}
</View>
        </View>

        <View style={styles.section}>
          <Text style={styles.headerSectionTitle}>{listingData?.BrandName} Collab Offer</Text>


<View style={styles.section}>
  {minFollowersRequired && (
    <View style={styles.followersRequirementBox}>
      <Text style={styles.followersRequirementText}>{minFollowersRequired} followers required</Text>
    </View>
  )}
</View>

          {showWarning && (
  <View style={styles.warningCard}>
    <Ionicons name="warning-outline" size={24} color="black" />
    <Text style={styles.warningText}>
      Hey! Your account is under review. You will not be able to propose bookings until your account is verified. Keep an eye on your inbox.
    </Text>
  </View>
)}

          <View style={styles.urlContainer}>
            <Ionicons name="link" size={20} color="black" />
            <TouchableOpacity onPress={() => Linking.openURL(`https://${listingData?.BrandURL}`)}>
              <Text style={styles.urlText}>{listingData?.BrandURL}</Text>
            </TouchableOpacity>
          </View>

          <View style={styles.descriptionBox}>
            <Text style={styles.description}>{listingData?.ListingDescription}</Text>
          </View>
        </View>

        <View style={styles.locationContainer}>
          <Ionicons name="location-sharp" size={20} color="black" />
          <Text style={styles.sectionTitleAddress}>{listingData?.Location}</Text>
        </View>



        <View style={styles.section}>
  {renderPreferredAvailability()}
</View>




        <View style={styles.locationContainer}>
          <Ionicons name="logo-instagram" size={20} color="black" />
          <Text style={styles.sectionTitleAddress}>Post Type : Reel</Text>
        </View>

        <View style={styles.locationContainer}>
  <Ionicons name="calendar" size={20} color="black" />
  <Text style={styles.sectionTitleAddress}>
    Turnaround Time: {listingData?.TurnAroundDays || 'N/A'} Days
  </Text>
</View>

        {/* Content Brief */}
        {renderContentBrief()}

        <View style={styles.creditsCard}>
          <Ionicons name="wallet" size={24} color="black" />
          <Text style={styles.creditsCardTitle}>Credit Value</Text>
          <Text style={styles.creditsCardSubTitle}>£{listingData?.Credits}</Text>
          <Text style={styles.creditsCardSubTitle}></Text>
          <Text style={[styles.creditsCardSubTitle, { fontWeight: 'bold', teextAlign: 'center' }]}>{listingData?.NonCreditOffer}</Text>
        </View>

        <View style={styles.calendarContainer}>
          <Calendar
            onDayPress={(day) => setSelectedDate(day.dateString)}
            markedDates={{
              [selectedDate]: {
                selected: true,
                selectedColor: '#FDF05D',
              }
            }}
            markingType={'simple'}
            theme={{
              todayTextColor: '#FDF05D',
              selectedDayBackgroundColor: '#FDF05D',
              selectedDayTextColor: 'black',
            }}
          />
        </View>

        {renderBookedSlots()}

<View style={styles.timePickerContainer}>
  <Text style={styles.timePickerLabel}>Select Time</Text>
  <ScrollView style={styles.timeScrollView}>
    {Array.from({ length: 40 }, (_, i) => {
      const hour = Math.floor((i + 14) / 2) % 24; // Shift to start at 7:00 AM
      const minute = (i % 2) === 0 ? '00' : '30';
      const ampm = hour >= 12 && hour < 24 ? 'PM' : 'AM';
      const adjustedHour = hour % 12 === 0 ? 12 : hour % 12; // Convert hour to 12-hour format
      const timeLabel = `${adjustedHour}:${minute} ${ampm}`;

      return (
        <TouchableOpacity
          key={i}
          style={[
            styles.timeSlot,
            selectedTime === timeLabel && styles.selectedTimeSlot
          ]}
          onPress={() => setSelectedTime(timeLabel)}
        >
          <Text>{timeLabel}</Text>
        </TouchableOpacity>
      );
    })}
  </ScrollView>
</View>


        <View style={styles.messageInput}>
          <Text style={styles.messageInputTitle}>  <Ionicons name="chatbubble-ellipses" size={20} color="black" /> Add a Message</Text>
          <TextInput
            style={styles.messageBoxInput}
            placeholder="Type your message here"
            multiline
            value={message}
            onChangeText={setMessage}
          />
        </View>

        <View style={styles.visitorInputContainer}>
          <Text style={styles.visitorInputLabel}>Additional Visitors?</Text>
          <Text style={styles.plusOneLabel}>Note | Plus ones are not directly included in collabbay offers. However, let the brand know if you wish to bring plus-ones and they can advise at their discretion.</Text>
      
          <View style={styles.visitorOptions}>
            <TouchableOpacity onPress={() => setAddedVisitors(0)}>
              <Text style={[styles.visitorOption, addedVisitors === 0 && styles.selectedVisitorOption]}>0</Text>
            </TouchableOpacity>
            {Array.from({ length: 5 }, (_, i) => (
              <TouchableOpacity key={i + 1} onPress={() => setAddedVisitors(i + 1)}>
                <Text style={[styles.visitorOption, addedVisitors === i + 1 && styles.selectedVisitorOption]}>+{i + 1}</Text>
              </TouchableOpacity>
            ))}
          </View>
         
        </View>

        <TouchableOpacity style={styles.collabButton} onPress={handleCollabPress}>
          <Text style={styles.collabButtonText}>Submit Collab Request</Text>
        </TouchableOpacity>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  contentContainer: {
    paddingHorizontal: 16,
  },
  bannerContainer: {
    position: 'relative',
    marginBottom: 16,
    marginTop: 12,
  },
  bannerImage: {
    width: '100%',
    height: 190,
    borderRadius: 10,
  },
  backContainer: {
    position: 'absolute',
    top: 16,
    left: 16,
  },
  backButton: {
    alignSelf: 'flex-start',
  },
  logo: {
    width: 100,
    height: 100,
    borderRadius: 50,
    marginLeft: 12,
    marginBottom: 15,
    borderColor: '#FDF05D',
    borderWidth: 2,
  },
  section: {
    marginBottom: 16,
  },
  headerSectionTitle: {
    fontSize: 22,
    fontWeight: 'bold',
  },
  descriptionBox: {
    backgroundColor: '#f0f0f0',
    borderRadius: 10,
    padding: 10,
    marginTop: 8,
  },
  description: {
    fontSize: 16,
    color: '#666',
  },
  locationContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginLeft: 8,
  },
  sectionTitleAddress: {
    fontSize: 16,
    marginLeft: 8,
  },
  creditsCard: {
    backgroundColor: '#FDF05D',
    padding: 16,
    borderRadius: 10,
    marginBottom: 16,
    alignItems: 'center',
  },
  creditsCardTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: 8,
  },
  creditsCardSubTitle: {
    fontSize: 16,
  },
  calendarContainer: {
    marginBottom: 16,
  },
  bookedSlotsContainer: {
    marginBottom: 16,
    backgroundColor: '#f0f0f0',
    borderRadius: 10,
    padding: 10,
  },
  bookedSlotsTitle: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 4,
  },
  bookedSlotsBody: {
    fontSize: 10,
    marginBottom: 8,
  },
  bookedSlot: {
    marginBottom: 4,
  },
  bookedSlotText: {
    fontSize: 16,
  },
  expandButton: {
    marginTop: 8,
    flexDirection: 'row',
    // alignItems: 'center',
  },
  expandButtonText: {
    color: '#007BFF',
    fontSize: 16,
    textDecorationLine: 'underline',
    marginLeft: 4,
  },
  timePickerContainer: {
    marginBottom: 16,
  },
  timePickerLabel: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  plusOneLabel: {
    fontSize: 10,
    marginBottom: 5,
    marginTop: 5,
  },
  timeScrollView: {
    height: 120,
  },
  timeSlot: {
    paddingVertical: 10,
    paddingHorizontal: 16,
    borderBottomWidth: 1,
    borderColor: '#eee',
  },
  selectedTimeSlot: {
    backgroundColor: '#FDF05D',
  },
  messageInput: {
    marginBottom: 16,
  },
  messageInputTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  messageBoxInput: {
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 8,
    padding: 10,
    fontSize: 16,
    backgroundColor: '#f0f0f0',
  },
  collabButton: {
    backgroundColor: '#FDF05D',
    paddingVertical: 14,
    borderRadius: 8,
    borderColor: 'black',
    alignItems: 'center',
    marginBottom: 30,
    borderWidth: 2,
  },
  collabButtonText: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  contentBriefContainer: {
    padding: 15,
    borderRadius: 10,
    borderColor: '#ddd',
    borderWidth: 1,
    marginVertical: 10,
  },
  bulletPoint: {
    fontSize: 16,
    color: '#333',
  },
  urlContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 10,
    padding: 5,
    marginVertical: 3,
  },
  urlText: {
    marginLeft: 8,
    fontSize: 16,
    color: 'black',
    textDecorationLine: 'underline',
  },
  logoContainer: { 
    position: 'absolute',
    top: 32,
    right: 0, 
    zIndex: 1,
    marginBottom: 16,
    marginTop: 50,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%', 
},
visitorInputContainer: {
  marginBottom: 16,
  padding: 10,
  backgroundColor: '#f0f0f0',
  borderRadius: 10,
},
visitorInputLabel: {
  fontSize: 14,
  fontWeight: 'bold',
  marginBottom: 8,
},
visitorOptions: {
  flexDirection: 'row',
  justifyContent: 'space-around',
},
visitorOption: {
  fontSize: 16,
  paddingVertical: 10,
  paddingHorizontal: 20,
  borderRadius: 10,
},
selectedVisitorOption: {
  backgroundColor: '#FDF05D',
},
warningCard: {
  backgroundColor: '#f0f0f0',
  padding: 16,
  borderRadius: 25,
  borderWidth: 1,
  borderColor: 'darkgrey',
  flexDirection: 'row',
  alignItems: 'center',
  marginVertical: 16,
},
warningText: {
  fontSize: 14,
  color: 'black',
  marginLeft: 10,
  flexShrink: 1,
},
sectionTitleAddress: {
  fontSize: 16,
  marginLeft: 8,
},
availabilityContainer: {
  marginTop: 16,
  padding: 12,
},
availabilityHeader: {
  fontSize: 18,
  fontWeight: 'bold',
  marginBottom: 8,
  color: '#333',
},
availabilityList: {
  flexDirection: 'row',
  flexWrap: 'wrap', // Allows items to wrap if necessary
  gap: 8,
},
availabilityItem: {
  backgroundColor: '#f0f0f0', // Light gray background
  paddingVertical: 8,
  paddingHorizontal: 12,
  borderRadius: 16,
  marginBottom: 8,
},
availabilityText: {
  fontSize: 14,
  color: '#333',
  textAlign: 'center',
},
noAvailabilityText: {
  fontSize: 14,
  fontStyle: 'italic',
  color: '#999',
  textAlign: 'center',
},

});

export default CreateSubmissionPage;
